<template>
    <div class="py-5 osahan-coming-soon d-flex justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="text-center pb-3">
                <h2 v-if="$store.state.curUser" class="font-weight-bold" >Thank You</h2>
        <p></p>
            </div>
            <!-- continue -->
            <div class="bg-white rounded text-center p-4 shadow-sm">
                <!-- <h1 class="display-1 mb-4">🎉</h1> -->
                <h1 class="display-1 mb-4 icon text-success"><i class="feather-thumbs-up"></i></h1>
                
                <h4 class=" text-muted">Your action was successful.</h4>
                <!-- <a href="status_onprocess" class="btn rounded btn-primary btn-lg btn-block">Track My Order</a> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      userName: JSON.parse(this.$store.state.curUser).user.user_name,
      lastOrder: JSON.parse(window.localStorage.getItem("lastOrder")),
      lastOrderCode:window.localStorage.getItem('lastOrderCode')
    };
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
};
</script>